@import url(https://db.onlinewebfonts.com/c/e080040fab017e068ee2901a6cdb5ec4?family=Lato);
.partner #primary .entry-content .become_driver {
    font-size: 16px;
    color: #111;
}
.partner div#primary {
    padding-top:4%;
    padding-bottom:6%;
}
.partner .entry-content, .entry-summary {
    border-color: #d1d1d1;
    margin: 0!important;
}
.become_driver {
    position: relative;
}
.become_driver {
    background: #fff;
   
    font-size: 14px;
    line-height: 22px;
    padding: 15px;
}
.become_driver{
    float: left;
    width: 100%;
}



.partner #primary p {
    font-size: 16.3px!important;
    padding-right: 15px;
}
.become_driver p {
    font-size: 16.3px!important;
    padding-right: 0;
    color: #000;
    margin-bottom: 18px;
    font-family:  'Lato';
    line-height: 22px;
    font-weight:400;
    letter-spacing:0px;
}
.become_driver h4 {
    font-size:24px;
    margin: 15px 0 5px;
    line-height: 28px;
    letter-spacing: 3px;
    font-weight:750;
    font-family: 'latoblack',sans-serif;
    text-transform: uppercase;
}
.pages_content {
    background: #ededed;
}
.becomepage {
    margin: 0 -15px;
}

.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #fff;
  min-height: 10vh;
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(14, 13, 13);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* ----------------------------------------------------------------------------------- */
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  /* background-color: #333; */
}

li {
  float: left;
}

li a {
  display: block;
  color: rgb(14, 13, 13);
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

li a:hover:not(.active) {
  background-color: #111;
}

.active {
 
  border-bottom: 2px solid #ededed;
}


h1,
p,
a{
  margin: 0;
  padding: 0;
  /* font-family: 'Lato'; */
}

h1 {
  font-size: 2.8em;
  padding: 10px 0;
  font-weight: 800;
}

p {
  font-size: 1.1em;
  font-weight: 100;

}

.headernew {
  width: 100%;
  padding:60px 0;
  text-align: center;
  background: rgb(194, 159, 159);
  color: rgb(8, 8, 8);
  position: absolute;
  top: 0;
 
}


.btn-bgstroke {
  font-size: 20px;
  display: inline-block;
  border: 1px solid white;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 300;
  margin-top: 30px; 
}

.btn-bgstroke:hover {
  background-color: white;
  color: #33cccc;
}



/* ----------------------------------------------------------------------------------- */

body.active-rcmodal {
  overflow-y: hidden;
}

.btn-rcmodal {
  padding: 10px 20px;
  display: block;
  margin: 100px auto 0;
  font-size: 18px;
}

.rcmodal {  
  z-index: 1000;
}

.rcmodal, .rcoverlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

.rcoverlay {
  background: rgba(49,49,49,0.8);
}
.rcmodal-content {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f1f1f1;
  /* padding: 14px 28px; */
  padding: 46px 94px;
  border-radius: 3px;
  max-width: 600px;
  min-width: 300px;
  text-align: center;
}


.rcmodal-content.payment {
  padding: 46px 10px;
}

.inp-file {
  text-align: center;
  margin: auto;  
  border: 1px solid #7b3995;
  border-radius: 6px;  
  padding: 14px 14px;
}

.btn-rmodal {
  background-color: rgb(123, 57, 149) !important;
  color: rgb(255, 255, 255) !important;
  padding: 7px !important;
  width: 28% !important;
  border-radius: 6px !important;
}

.close-rcmodal {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 6px 12px !important;
  margin: 0 !important;
  width: auto !important;  
  background-color: #7b3995 !important;
  /* border-radius: 13px !important; */
}

.ptotal {
  background: rebeccapurple;
    width: 50% !important;
    margin: 0 auto;
    padding: 10px;
    border-radius: 6px;
    color: #fff;
    font-weight: 600;
}

.balance {
  text-align: center;
  display: inline-block;
}

.balance li:first-child {
  background: rebeccapurple;
    color: #fff;
}

.balance li {
  padding: 5px 35px;
  border: 1px solid rebeccapurple;
}





.dashboard .priceing-cont  {
    float: left;
    width: 100%;
    margin-bottom: 44px;
    margin-left: 4%;
    padding: 3%;

}
.dashboard .priceing-cont ul {
    float: left;
    padding: 0 3px;
    width: 100%;
}

.dashboard .priceing-cont ul li {
    float: left;
    margin: 0 12px;
    text-align: center;
    min-height: 152px;
    padding: 31px 8px 8px;
    width:16%
}
.dashboard .priceing-cont ul li h3 {
color: #fff;
    font-family: latobold;
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 8px;
    min-height: 45px;
}

.dashboard li.bg_blue {
    background:#3598dc 
}
.dashboard li.bg_orange {
    background:#d6a546
}
.dashboard li.bg_chocolate {
    background:#ae8e45
}
.dashboard li.bg_purple {
    background:#8f44ad 
}
.dashboard li.bg_lightblue {
    background:#46cad6
}

.dashboard .priceing-cont ul li span {
    color: #fff;
    font-family: segoe ui;
    font-size: 35px;
    font-weight: 700;
    line-height: 35px;
}

.dashboard .pending-order-sec {
    float: left;
    margin-bottom: 50px;
    width: 100%;
    padding-left: 9%;
    padding-right: 9%;
}
.dashboard .pending-order-cont {
    background: #fff none repeat scroll 0 0;
    float: left;
    padding-bottom: 34px;
    padding-top: 50px;
    text-align: center;
}
.dashboard .pending-order-cont .progress-graph.position {
    margin: 0 auto;
    display: block;
    float: none;
}

.dashboard .recharts-wrapper{
    margin:auto;
}
.apexcharts-legend.apexcharts-align-center.apx-legend-position-right {
    display: none !important;
}

.donut {
    padding-left: 11%;
} 
ul.recharts-default-legend {
    display: none;
}

#loading{
    background: url(images/home/spin.gif) no-repeat ;
    position: absolute;
    left: 42%;
    height: 100%;
    width: 24%;
}
.dashboard .dropdown .dropdown-menu .dropdown-item{
    padding:8%;
    border-bottom:1px solid;
    color: #fff;
    text-align: center;
}
.dashboard .dropdown .dropdown-menu
{
    background: #8f44ad;
}

.dashboard .dropdown-item:focus, .dropdown-item:hover {
    color: #fff;
    background-color: #7e18a7;
}
.data-table-extensions > .data-table-extensions-action > button.print,.data-table-extensions > .data-table-extensions-action > button.download {
    min-width: 30px !important;
}
.filterbtn{
    min-width: 20px;
    text-align: center;
    margin: auto;
    margin-top: 2%;
}


/* Testimonial */

.single-testimonial .become_driver>h3 {
    color: #7f3f98;
    display: block;
    font-family: latobold;
    font-size: 20px;
    text-align: right;
    font-weight: bold;
}

.improvelive h3 {
    font-size: 17px;
    font-weight: bold;
}

.becomedriver .become_driver.post_driver .ourservies h2 {

    font-size: 21px;
    letter-spacing: 1px;
    margin: 0 0 12px;
    text-transform: uppercase;
}

span.wpcf7-list-item {
    display: block;
}

span.wpcf7-list-item-label {

    margin: 2%;
}

.salequery button {
    background: purple;
    color: #fff;
    border: 2px solid;
    border-radius: 6px;
    padding: 9px;
    width: 12%;
}

.shopusa .ship-more a {
    background: purple;
    margin-top: 10px;
    color: #fff;
    display: inline-block;
    padding: 6px 20px;
    text-decoration: none;
}

.shopusa .become_driver p {
    margin-bottom: 5px;
}

a.dlink {
    cursor: pointer;
}

.data-table-extensions>.data-table-extensions-filter>.icon {
    display: none !important;
}

section.your_business_page.fw:after {
    background: rgba(0, 0, 0, .3);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.form-group.col-md-6 {
    padding-bottom: 7px;
}

.input-group-addon {
    font-family: 'Lato' !important
}

.improvelive strong {
    font-size: 25px;
}

section.your_business_page.fw {
    box-shadow: inset 0 0 0 50vw #0000004d;
}

.loginaction {
    margin-left: 4%;
    color: purple;
    cursor: pointer
}

.resethead {

    padding-left: 0;
    padding-right: 0;
    text-align: center;
    padding: 15px;

}

.reset_message {
    background-color: #2679ce;
    border-color: #2679ce;
    padding-left: 10px;
    margin-bottom: 50px;
    padding: 15px;
    color: #fff;
    text-align: center;
}

.sociallogin button {
    height: 40px !important;
}

.sociallogin div {
    font-size: 13px;
}

svg.svg-inline--fa.fa-envelope,
svg.svg-inline--fa.fa-square-phone {
    font-size: 17px !important;
}

.custom_sub {
    font-size: 22px !important;
}

.mainhead {
    color: #fff;
    font-size: 50px;
    font-weight: 500;
    line-height: 60px;
    text-transform: uppercase;
}
.mainhead2 {
    margin-top: -60px;
 }
@media (max-width: 767px) {
    .mainhead {
        font-size: 35px;
    }

    .wrape_promo {
        margin-top: 3%;
    }

    .business_content h2 {
        font-size: 35px;
    }

    .salequery button {
        width: 100%
    }

    .cta-header {
        display: block;
    }

    h3.cta-header {
        text-align: initial;
        padding: 0
    }

    table {
        display: block;
        overflow-x: auto;
    }

    .mybusiness .business_region>li {
        max-width: 100%
    }

    .loginbox {
        border: none !important;
        margin-top: 0% !important;
    }

    .ReactModal__Content.ReactModal__Content--after-open {
        width: 100% !important;
        inset: 0px !important;
    }

    .dashboard .priceing-cont {
        margin-left: 0 !important;
    }

    .dashboard .priceing-cont ul li {
        /* float: left; */
        margin: 0 !important;
        text-align: center;
        min-height: 0 !important;
        padding: 31px 8px 8px;
        width: 100%;
    }

    .donut {
        padding-left: 0 !important;
    }

    .pending-order-sec.text-center {
        display: block;
        overflow-x: auto;
    }

    .trackbtn {
        width: 40%;
        margin: 10px;
        height: auto !important;
    }

    .loginaction {
        display: block
    }

    a#collection_details {
        max-width: 100px !important;
        min-width: 100px !important;
        margin-bottom: 5%;
    }

    .adrresscheck {
        margin-top: 3%;
    }

    .addresslink {
        float: right;
        border-radius: 30px !important;
    }

    .order ul.customradio li {
        float: left !important
    }

    .mybusiness h2 {
        font-size: 15px !important;
        line-height: 22px;
        padding: 6%;
    }

    .business_content span {
        font-size: 15px;
    }

    .buss label {
        font-size: 17px !important;
    }

    .business_needs_sec {
        padding: 22px 0;
    }

    .salesbtn {
        display: block;
        width: 40% !important;
        min-width: 40% !important;

        border-radius: 6px !important;
    }

    a.add_btn {
        min-width: 200px;
    }

    .ReactModal__Content.ReactModal__Content--after-open {
        margin: 0 !important;
    }

    label.label--reg {
        font-size: 13px;
    }

    div#cd-intro-background {
        padding: 0;
        background: #7f3f98;
        background-image: none !important;
    }

    .circle:after{
        display:none;
    }

}
.smartbtn {
    height: 30px;
    font-family: 'Roboto', sans-serif;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-weight: 500;
    color: #7f3e98;
    background-color: #fff;
    border: none;
    border-radius: 45px;
    box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
    min-width: 65%;
    max-width: 80%;
    font-weight: bold;
}


.smartbtn:hover {
    background-color: #7f3e9885;
    ;
    box-shadow: 0px 15px 20px rgba(8, 8, 8, 0.56);
    color: #fff;
    transform: translateY(-7px);
}

.smartbtn2 {

    /* width: 140px; */
    height: 30px;
    font-family: 'Roboto', sans-serif;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-weight: 500;
    color: #fff;
    background-color: #7f3e9885;
    border: none;
    border-radius: 45px;
    box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
    min-width: 65%;
    max-width: 80%;
    font-weight: bold;
}


.smartbtn2:hover {
    background-color: #fff;
    ;
    box-shadow: 0px 15px 20px rgba(8, 8, 8, 0.56);
    color: #7f3e98;
    transform: translateY(-7px);
}

.shopifymain .shopify1{
    background: #95bf47;
    color: #fff;
}
.shopifymain  .shopify{
    background:#fff;
    color:#95bf47; 
}

.shopify1{
    
    position: relative;
    animation: animateright 0.9s;
    width: 32.333333% !important;
    padding:2%;
}
.shopify{
   
    position: relative;
    animation:animatebottom 0.9s;
    width: 32.333333% !important;
    padding:2%;
}

.smartsendmain{
    justify-content: space-between;
}
.shopify h5,.shopify1 h5{
    font-weight:bold
}
.shopifymain svg.svg-inline--fa.fa-circle-check {
 
    color: #95bf47;
}
.shopifywork p{
    position: relative;
    animation: animatebottom 0.8s;
}

.fade-in-section {
    opacity: 0;
    transform: translateY(20vh);
    visibility: hidden;
    transition: opacity 1200ms ease-out, transform 600ms ease-out,
      visibility 1200ms ease-out;
    will-change: opacity, transform, visibility;
  }
  .fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
  }
  svg.svg-inline--fa.fa-circle-exclamation {
    color: #e7ae46;
}

.woocommerce .shopify1{
    background: #96588a;
    color: #fff;
    padding:2%;
}
.woocommerce  .shopify{
    background:#fff;
    color:#96588a; 
}
.woocommerce svg.svg-inline--fa.fa-circle-check {
 
    color: #96588a;
}
.magento .shopify1{
    background: #f46f25;
    color: #fff;
}
.magento  .shopify{
    background:#fff;
    color:#f46f25; 
}
.magento svg.svg-inline--fa.fa-circle-check {
 
    color: #f46f25;
}
/* for googlebutton */

.nsm7Bb-HzV7m-LgbsSe.hJDwNd-SxQuSe.i5vt6e-Ia7Qfc.uaxL4e-RbRzK {
    border-radius: 25px !important;
}
.nsm7Bb-HzV7m-LgbsSe{
    border-radius: 25px !important;
}

.career_box {
    background: #fff;
    float: left;
    margin: 0 2% 20px
px
 0;
    max-height: 500px;
    overflow: auto;
    padding: 15px;
    transition: all .4s ease 0s;
    width: 32%;
}
.careersub{
    color: #7f3e98;
    font-weight: 600;
}

@media (max-width: 767px) {
    .career_box{
        width: 100%;
        margin-top: 3%;
    }
    
}

.bloglist{
    list-style: disc !important;
    padding: 3% !important;
}
.blogmain ul{
    list-style: disc !important;
    padding: 3% !important;
}

.blogbtn{
    background: #843e8763 !important;
    border: 1px solid #e2bee4;
}

.disabled-date {
    pointer-events: none;
  }

  .react-datepicker__navigation-icon--next:before {
    left: 80px;
}

.react-datepicker__navigation-icon--previous:before {
    right: 80px;
}
.h1-title{
    color:#ffff;
    text-transform: uppercase;
    font-size: 50px;
}
@media (max-width: 767px){
    .h1-title {
        font-size: 35px;
    }
}


a.panel-title.my-wallet-rec {
    border-radius: 25px;
    padding: 1%;
    font-size: 13px;
    width: 150px;
    margin-right: 10px !important;
    cursor:pointer
}

.eUeqdG{
    white-space: pre-line !important;
}

.sc-evZas {
    white-space: pre-line !important;
}
.bMgaAx div:first-child {
    white-space: pre-line !important;
 }

 .dBbhDz div:first-child{
    white-space: pre-line !important;
 }

 .awbprint{
    page-break-after: always !important;
 }


 .bulkupload >.ReactModal__Content--after-open {
    height: 299px !important;
 }

 .bulkpay{
    cursor: pointer;
    background: #d85b12;
    color: #fff;
}

.bulkpaybtn{
    display:block;
    text-align:center;

}
 

.bulkuploadhead{
    font-size: 15px !important;
    text-transform: uppercase;
}

.bulkinfo{
    background: #d85b12a1;
    color: #fff;
    border-radius: 50%;
    font-size: 13px;
    padding-left: 0.6%;
    padding-right: 0.6%;
    cursor: pointer;
}

.stepsupload{
    font-size: 1rem;
}

.bulkicons{
    border-radius: 50%;
    padding: 8%;
    width: 40px;
    color: #2c4079;
    height: 40px;
    border: 2px solid #2c4079;
    font-size: 10px;
}

.circle:after {
    content: "";
    border-top: 3px solid #2c4079;
    /* border-color: #cbcbcb; */
    height: 100%;
    position: absolute;
    bottom: -45px;
    z-index: 8;
    /* left: 166px; */
    width: 64%;
    right: -90px;
}

.staffdiscount p{
    font-family: cursive !important;
}

section.your_business_page.fw.bgN::after {
    content: "";
    background: #00000087 !important;
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&family=Roboto:wght@300;400&display=swap');
.track-parcel h3 {
    color: #fff;
    font-family: ralewaybold;
    font-size: 27px;
    padding-bottom: 5px;
    text-align: left;
    width: 95%;
  
}
#tracking_track .business_region {
    width: 100%;
}
.track-parcel .business_region {
    margin-bottom: 0;
}
.business_region {
    float: left;
    width: 100%;
}
.track-parcel .form-control {
    padding: 8px 20px;
}
#tracking_track .business_region {
    width: 100%;
}

.business_region .form-control, .send_weight .business_region input {
    border: 0;
    border-radius: 0;
    color: #000;
    font-family: ralewaybold;
    font-size: 16px;
    height: 60px;
}
input[type=text]{
    background: #f7f7f7;
    background-image: -webkit-linear-gradient(rgba(255,255,255,0),rgba(255,255,255,0));
    border: 1px solid #d1d1d1;
    border-radius: 2px;
    color: #686868;
    padding: 0.625em;
    width: 100%;
}
.track-parcel .form-control {
    padding: 8px 20px;
}

.business_region button.form-control {
    background: #7f3f98;
    color: #fff;
    text-transform: uppercase;
    transition: all .5s ease 0s;
    -webkit-transition: all .5s ease 0s;
    -ms-transition: all .5s ease 0s;
}

.track .business_region>li {
    display: inline-block;
    vertical-align: middle;
    max-width: 322px;
    width: 100%;
    position: relative;
    z-index: 9;
    float:none !important;
}
#tracking_track .business_region>li:first-child {
    max-width: 525px;
    width: 100%;
}
a.masthead__findtrack {
    background: #000;
    color: #fff;
    padding: 1%;
    text-decoration: none;
}
.become_driver {
    background: #fff;
    font-family: 'Lato';
    font-size: 14px;
    line-height: 22px;
    padding: 15px;
}
.become_driver h2 {
    font-family: 'Montserrat';
    font-size: 27px;
    font-weight: bold;
}
.pages_content {
    padding: 45px 0 0!important;
}
.pages_content {
    background: #ededed;
    float: left;
    width: 100%;
}
.track-hedding {
    padding-top: 2% !important;
    padding-bottom: 4% !important;

}
.track-hedding h1 {
    color: #000;
    font-family: 'Montserrat';
    font-size: 30px;
    text-align: center;
}
.business_needs {
    padding: 0 20px;
    top: 40px;
    padding-top: 12%;
    padding-bottom: 12%;
    min-height: 336px;
   
}
.business_needs .block__title {
    color: #fff;
    font-family: 'Montserrat';
    font-size: 23px;
    line-height: 38px;
    margin-bottom: 20px;
    text-transform: none;
    font-weight: 600;
    text-align: center;
}
h3{
    font-weight: 400;
    margin-top: 0;

}
.business_needs>p {
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    line-height: 21px;
    /* padding: 2%; */
    text-align: center;
    font-weight: 400;
    letter-spacing: 0px;

}
.track #primary p {
    font-size: 16.3px!important;
    padding-right: 15px;
}
.needs_but {
    bottom: 30px;
}
.needs_but {
   text-align: center;
    width: 100%;
    padding-bottom: 8%;
    padding-top: 8%;
}
.needs_box:hover .btn--black {
    background: #fff;
    color: #000 !important;
}
.needs_but .btn.btn--black {
    border-color: #fff;
    color: #fff;
    font-size: 16px;
    margin: 0;
    padding: 10px 25px;
    float: none;
}
.business_img{
    background-image: url(images/banners/bus.jpg);
    background-size: cover;
}

.delivery-tracking .time-sutes {
    float: left;
    text-align: center;
    width: 100%;
}
.delivery-tracking .time-sutes h3 {
    color: #000;
    font-family: ralewaybold;
    font-size: 30px;
    letter-spacing: .5px;
    line-height: 35px;
    text-align: center;
}
.time-sutes .box-time {
    border: 2px solid #7f3f98;
    border-radius: 4px;
    display: inline-block;
    float: none;
    line-height: 40px;
    margin-top: 40px;
    min-width: 65%;
}
.circle-sec.col-md-12.col-sm-12 {
    text-align: center;
}
.circle-sec .circle_status {
    display: inline-block;
    margin: 28px 0 8px;
    min-width: 60%;
    padding-left: 75px;
}
.delivery-tracking .circle-img {
    margin-right: 52px;
    position: relative;
}

.circle_status .status-driver-content {
    text-align: left;
}
.status-driver {
    float: right;
    margin-bottom: 25px;
 
}
#primary .status-driver p {
    color: #7f3f98;
    font-family: sans-serif;
    font-size: 18px!important;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 4px;
}
.status-driver span {
    color: #000;
    font-family: latoregular;
    font-size: 28px;
    line-height: 30px;
}
.circle_status .status-driver-content {
    display: inline-block;
    vertical-align: middle;
}
.circle_status .circle-img{
    display: inline-block;
    vertical-align: middle;
}
.delivery-tracking .Parcel-box {
    background: #fff none repeat scroll 0 0;
    float: left;
    width: 100%;
}
.delivery-tracking .parcel-table .Parcel-box h3 {
    background: #7f3f98 none repeat scroll 0 0;
    box-shadow: 0 3px 4px #989898;
    color: #fff;
    font-family: ralewaybold;
    font-size: 20px;
    padding: 20px 15px;
    text-transform: uppercase;
    letter-spacing: .5px;
}
#primary .delivery-tracking .Parcel-box p {
    color: #000;
    font-family: latoregular;
    font-size: 24px!important;
    font-weight: 500;
    letter-spacing: .5px;
    margin-top: 35px;
    padding-left: 30px;
}
.track.table-responsive {
    min-height: .01%;
    overflow-x: auto;
}
.delivery-tracking .Parcel-box table {
    border: 0;
    margin-top: 55px;
    margin-bottom: 55px;
}
table {
    background-color: transparent;
    border-spacing: 0;
    border-collapse: collapse;
}
.delivery-tracking .Parcel-box th {
    border-bottom: 1px dashed #b2b2b2;
    border-left: 0;
    border-right: 0;
    font-family: avenirnextltpro-bold;
    font-size: 20px;
    letter-spacing: .5px;
    padding: 6px 10px 25px 38px;
    color: #000;
}
th {
    text-align: left;
}
.delivery-tracking .Parcel-box table td {
    border-bottom: 1px dashed #b2b2b2;
    border-right: 0;
    font-size: 15px;
    padding: 12px 7px 12px 38px;
    font-family: avenirnextltpro-regular;
}
.inner-cont-trek {
    border-bottom: 1px solid #efefef;
    margin-bottom: 40px;
    padding-bottom: 20px;
}
.delivery-tracking .parcel-table .btn-rate {
    float: left;
    margin-top: 50px;
    text-align: center;
    width: 100%;
}
.delivery-tracking .parcel-table .rate-delivery {
    background: #7f3f98 none repeat scroll 0 0;
    border-radius: 40px;
    color: #fff;
    display: inline-block;
    font-family: avenirnextltpro-bold;
    font-size: 20px;
    line-height: 55px;
    margin: 0 auto;
    min-width: 370px;
    text-align: center;
    text-transform: uppercase;
    box-shadow: 0 1px 12px #676767;
}



/* rating styles */
.ReactModal__Overlay.ReactModal__Overlay--after-open{
    z-index: 1000;
}
.ratepop{
    text-align: center;
}
.ratepop h3{
    color: #000;
    font-family: 'Montserrat';
    font-size: 24px;
    letter-spacing: .5px;
    line-height: 28px;
    font-weight: 700;
}
.ratepop h2{
    border-bottom: 2px solid #cfcfcf;
    color: #602f71;
    font-family: 'Montserrat';
    font-size: 27px;
    letter-spacing: 2px;
    line-height: 40px;
    padding-bottom: 12px;
    margin-bottom: 0;
    font-weight: 600;
}
.ratepop p{
    font-family: 'Montserrat';
    font-size: 18px;
    letter-spacing: 3px;
    line-height: 30px;
    margin-bottom: 20px;
    padding-top: 20px;
}
.ratepop .send_btn{
    background: #7f3f98 none repeat scroll 0 0;
    border-radius: 40px;
    color: #fff;
    display: block;
    font-family: avenirnextltpro-bold;
    font-size: 18px;
    line-height: 45px;
    margin: 18px auto 0;
    width: 235px;
    text-align: center;
    text-transform: uppercase;
    box-shadow: 0 1px 12px #676767;
    cursor: pointer;
}


textarea.ratings_comment {
    margin-top: 55px;
    height: 100px;
}
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    background: #f7f7f7;
    background-image: -webkit-linear-gradient(rgba(255,255,255,0),rgba(255,255,255,0));
    border: 1px solid #d1d1d1;
    border-radius: 2px;
    padding: 0.625em 0.4375em;
    width: 100%;
}
.track .ReactModal__Content.ReactModal__Content--after-open{
    height:500px;
    width: 70% !important;
    margin: auto;
    margin-top: 3%;

}

.track-detail .needs_box {
    margin-bottom: 30px;
} 
@media (max-width: 767px){
    .needs_box {
        margin-bottom: 10px;
    }
}


.sociallogin .nsm7Bb-HzV7m-LgbsSe.hJDwNd-SxQuSe.i5vt6e-Ia7Qfc.uaxL4e-RbRzK {
    border-radius: 25px !important;
}
.sociallogin .qJTHM .nsm7Bb-HzV7m-LgbsSe{
    border-radius: 25px !important;
}

.container .nsm7Bb-HzV7m-LgbsSe {
    border-radius: 25px !important;
}

.footer {
    background-color: #0b0b0b;
    padding: 46px 0 50px;
    border-top: 1px solid #292929;
}
.footer {
    color: #fff !important;
}
.footer .footer-nav-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 16px;
}
.text-light {
    color: #f8f9fa!important;
}

.help a.nav-item.nav-link {
    background: #7f3f98;
    color: #fff;
    padding-top: 6%;
    margin-right: 14px;
    vertical-align: middle;
    width: 264px;
    border-radius: 0px;
    font-size: 19px;
    font-weight: 600;
    border:2px solid;
}
.contact a.nav-item.nav-link {
    background: #7f3f98;
    color: #fff;
    padding-top: 6%;
    margin-right: 14px;
    vertical-align: middle;
     width: 269px;
    border-radius: 0px;
    font-size: 19px;
    font-weight: 600;
    border:2px solid;
}
.nav-tabs {
    border-bottom: 1px solid #dee2e6;
    /* background: #7f3f98; */
    color: #fff;
    min-height: 153px;
    text-align: center;
    vertical-align: middle;
}
.details{
    padding: 3%;
    background: #fff;
    margin-top: 2%;
    margin-bottom: 2%;
}

#cd-intro{
    background: #ededed;
}
.helpnav{
    margin-top:3%;
    margin-bottom:3%;
}


.admin-sidenav {
    width: auto;
	height: auto;
	margin-left: 0px; 
}
.admin-sidenav a {
	text-decoration: none; 
}
.admin-sidenav li {
	text-align: justify;
	/* padding: .5rem; */
	/* padding-left: 1rem; */
	-webkit-transition: all .2s linear;
	transition: all .2s linear;
	background-color: #2c4079;
	/* border: 1px solid #333;  */
	width:100%;
	display:block
}
.admin-sidenav li a {
	color: #fff; 
}

.admin-sidenav li a:active {
	border-color: #02d3f5; 
}

.admin-sidenav li:hover {
	border-radius: 0 .5rem .5rem 0;
	border-color: #02d3f5;
	-webkit-transform: translate(30px, 0px);
	transform: translate(30px, 0px);
	background-color: #2c4079;
	/* background: -webkit-linear-gradient(left, #006a7b, #002340);
	background: linear-gradient(to right, #006a7b, #002340);  */
}

.admin-sidenav li:active {
	border-color: #02d3f5; 
}
.thead-dark{
	background: #2c407a;
	color:#fff
}
.awbtd{
	text-decoration: none;
	font-size: 12px;
    padding: 0 4px;
    color: #fff;
    display: inline-block;
    background: #d85b12;
    margin: 2px 0;
    height: 30px;
    line-height: 30px;
    width: 100%;
    text-align: center;
    border-radius: 4px;
}

.sidebar li a:hover:not(.active) {
    background-color: rgb(8, 8, 8);
    color: #fff !important;
    border-bottom: 2px solid !important;
}
.sidebar li a{
	padding: 8px 16px !important;
	font-weight:bold
}
.sidebar .active{
	background-color: #d85b12;
    color: #fff !important;
}

.my_parcels .printbtn{
	background: #7f3f98;
    color: #fff;
    padding: 1%;
    border: 1px solid #7f3f98;
    width: 15%;
    border-radius: 6px;
    margin-bottom: 3%;
}
.ReactModal__Content.ReactModal__Content--after-open {
    height: 500px;
    width: 55% !important;
    margin: auto;
    margin-top: 3%;
    
}



.my_quote .active{
	background: #d85b12;
	color:#fff
}
.my_quote li a{
	color:#fff;
	padding: 2px 16px;
}
.my_quote ul.nav.nav-pills {
    background: #f1f1f3;
    border: 1px solid #d85b12;
}
.my_quote li a:hover:not(.active) {
    background-color: rgb(216 91 18);
    color: #fff !important;
    border-bottom: 2px solid !important;
}
.my_quote a#import-link {
    color: #000;
	border: 1px solid #7f3f98;
}

ul.business_region.quotesec2.export-area>li {
    width: 40%;
}

.my_quote .btn_same1{
	background: #d85b12 !important;
    border: none;
    border-radius: 6px;
    font-weight: 400 !important;
    color: #fff;
}

.my_account_tracking .comaprison_hedding {
    color: #2c4079;
    font-size: 20px;
    font-weight: 600;
}

.my_account_tracking ul.business_region li button.btn.form-control {
    margin: 0;
    min-width: 200px;
    background: #d85b12!important;
}
.my_account_tracking ul.business_region li button.btn.form-control:hover {
    background: #2c4078!important;
}

.wallet-plan button {
    color: #fff;
    background-color: #d85b12bf;
    width: 35%;
    padding: 4px;
    margin-left: 20px;
    border: none;
    min-width: max-content!important;
}

h3.comaprison_hedding {
    color: #2c4079;
    font-size: 20px;
    font-weight: 600;
}

.wallet-plan .table>thead {
    vertical-align: bottom;
    background: #2c407a;
    color: #fff;
}

.addressbook  tr td a {
    font-size: 12px;
    padding: 0 4px;
    color: #fff !important;
    display: inline-block;
    background: #d85b12;
    margin: 2px 0;
    height: 30px;
    line-height: 30px;
    width: 100%;
    text-align: center;
    border-radius: 4px;
}

.addressbook tr td a:hover {
    background: #2c4078;
}

.addressbook .add_btn{
    text-decoration: none;
    font-size: 15px;
    
    color: #fff !important;
    display: inline-block;
    background: #d85b12;
    margin: 2px 0;
    /* height: 30px; */
    line-height: 30px;
    width: 23%;
    text-align: center;
    border-radius: 30px;
    margin-left: 5px;
    padding: 3px;
}
.addressbook .add_btn:hover{
    background: #2c4078;
}


.date-holder {
    /* float: left; */
    width: 50%;
    padding: 0;
}

.filtbox {
    text-align: right;
    position: relative;
}

.filtersec {
    position: absolute;
    right: 0;
    top: 25px;
    padding: 1%;
    background: #8f44ad;
    border-radius: 6px;
    border: 1px solid #efe8e8;
}

.date-holder .shipping_date{
    background: none;
    color: #fff;
    border: 1px solid #fff;
    margin-bottom: 1px;

}
.myparctab{
    margin:6% 3% 6% 7%
}

.quotetab{
    padding-left:2%;
}

input.input--small {
    border: 1px solid #e3e1e1 !important;
   
}

.adjustspace{
    margin-left:11px !important;
}

.ReactModalPortal button {
    min-width: max-content;
    border-radius: 6px !important;
}

.booking{
    background: #d85b12;
    color: #fff;
    padding: 1%;
    margin-right: 3px;
    border-radius: 5px;
    border: 1px solid #d85b12;
}
.onlinewallet{
    background: #fff;
    color: #d85b12;
    border: 1px solid #d85b12;
    padding: 1%;
    margin-right: 3px;
    border-radius: 5px;
}
.wallettrans{
    margin-top: 2%;
    margin-bottom: 2%;
}

@media (max-width: 767px){
.myparceltable{
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid #ddd;
    /* position: relative; */
    display: block;
    overflow-x: auto;
}

.myparctab{
    margin:0
}

.walletmoney{
    margin:0
}

.wallet-plan button{
    width: 100% !important;
    margin: 0 !important;
   
    font-size: 13px;
    min-width: max-content!important;
}
}

.bookingpage li a {
    color: #242020;
}


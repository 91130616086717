
.quick-quote-home-bg {
    height: 640px;
    /*background-size: cover;*/
    overflow: hidden;
    
}
.quick-quote-home-bg::after {
    /* background-image: url(images/home/top-img_shedow.png); */
    background-repeat: no-repeat;
    bottom: -150px;
    width: 100%;
    height: 35px;
    position: absolute;
    background-size: cover;
    content: "";
    left: 0;
}
.quick-quote-home {
    margin-top: -624px;
}
.img-container img {
    width: 100%;
    margin-top: -96px;
}
.img-container {
    height: 0;
    padding: 0;
}
.tab-tittle-page h1 {
    color: #fff;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: capitalize;
    text-align: center;
    font-size: 58px;
    line-height: 60px;
    text-shadow: 0 1px 2px rgb(0 0 0 / 60%);
    margin-top: 20px;
    margin-bottom: 10px;
}

svg.svg-inline--fa.fa-whatsapp {
 
    color: #02ae22;
    border: 0;
    font-size: 18px;
    margin-right: 2px;
}

/* Tab Quick Quotes */		
    .tab-quick-quote .nav-tabs .nav-link {
        border-color: #d5d5d5;
        background-color: #d5d5d5;
        color: #000;
        margin-right: 2px;
    }
    
    .tab-quick-quote .nav-tabs .nav-item.show .nav-link, .tab-quick-quote .nav-tabs .nav-link.active {
        background-color: #7f3f98;
        color: #fff;
        border-color: #7f3f98;
    }


    
    .quick-holder {
        background-color: #fff;
        padding: 25px 0;
        display: flex;
        border-radius: 4px;
    }
  
    
    .quick-holder .col-md-5, .quick-holder .col-md-7 {
        padding: 0 15px;
    }
    
    .quick-holder h3 {
        font-weight: 600;
        color: #4b609b;
        font-size: 16px;
        margin: 0;
        margin-bottom: 10px;
    }
    
    .tab-in-export-import, .tab-send-road {
        border-bottom: 2px solid #844094;
        float: left;
        margin-bottom: 8px;
        padding: 0;
        width: 100%;
    }
    
    .tab-in-export-import a.active, .tab-package-document a.active, .tab-send-road a.active {
        color: #fff;
        background: #7f3f98;
    }
    
    .tab-in-export-import .export-link, .tab-in-export-import .import-link, .tab-package-document, .tab-ltl-ftl {
        float: left;
    }
    
    .tab-send-road .road-cross-border-link, .tab-send-road .road-domestic-link {
        float: left;
    }
    
    .tab-send-road .road-cross-border-link, .tab-send-road .road-domestic-link {
        border: 1px solid #7f3f98;
        border-radius: 5px;
        padding: 5px 15px;
        margin: 0;
        margin-bottom: 2px;
        font-weight: 700;
        font-size: 14px;
        text-decoration: none;
        color: #000;
        margin-right: 2px;
    }
    
    .tab-in-export-import .export-link, .tab-in-export-import .import-link, .tab-package-document a, .tab-ltl-ftl a {
        border: 1px solid #7f3f98;
        border-radius: 5px;
        padding: 5px 15px;
        margin: 0;
        margin-bottom: 2px;
        font-weight: 700;
        font-size: 14px;
        text-decoration: none;
        color: #000;
        margin-right: 2px;
    }
    
    .tab-package-document, .tab-ltl-ftl {
        padding-left: 5rem;
        display: flex;
    }
    
    ul.from-to {
        margin: 0;
        padding: 0;
        list-style: none;
        margin-bottom: 8px;
        position: relative;
        width:100%
    }
    
    ul.from-to>li {
        float: left;
        margin-right: 4px;
        width: 49%;
    }
    
    .weight-section {
        margin-bottom: 1rem;
        /*padding: 1rem 1rem 1rem;*/
        padding: 22px 12px;
        border: 1px solid #ddd;
        position: relative;
        float: left;
        width: 100%;
    }
    
    .weight-section li{
        width: 23.6%!important;
        float: left;
    }
    
    .quick-holder .add-btn {
        color: #7f3f98;
        background-color: #fff;
        border-color: #7f3f98;
        font-weight: bold;
    }
    
    .hr_border {
        height: 2px;
        color: white;
        opacity: inherit;
        margin: 20px 0;
        width: 100%;
    }
    
    /*.delete_more_sizes_in_package {*/
    .delete_more_sizes {
        background-color: #fff;
        color: red;
        width: 25px;
        height: 25px;
        border: 1px solid red;
        border-radius: 50%;
        position: absolute;
        top: -10px;
        right: -5px;
        line-height: 21px;
        padding: 0;
        text-align: center;
        z-index: 99;
    }
    
    .mark, mark {
        padding: 0.2em;
        background-color: #fcf8e3;
        font-size: 16px;
    }
    
    button#get_quote {
        padding: 8px 65px;
        background: #7f3f98;
        font-size: 18px;
        font-weight: 700;
        border-color: #7f3f98;
    }
    
    
    #add_more_weight_freight .weight-section li, #add_more_weight_ltl .weight-section li, #add_more_weight_ftl .weight-section li, #add_more_weight_rd .weight-section li {
        width: 18.6%!important;
    }
    
    .tab-in-export-package {
        max-height: 21%;
    }

    /* Parcels. Delivered Yesterday. CSS */
.parcelsdelivered .sec-1-title {
    text-align: center;
    color: #feb300;
    font-size: 35px;
}

.parcelsdelivered .pdy-img-holder {
    margin-top: 43px;
    overflow: hidden;
}

.parcelsdelivered .cont-holder {
    margin-top: 75px;
    float: left;
}

.parcelsdelivered .cont-holder span {
    transition: all .5s ease 0s;
    -webkit-transition: all .5s ease 0s;
    -ms-transition: all .5s ease 0s;
    -moz-transition: all .5s ease 0s;
    -o-transition: all .5s ease 0s;
}

.parcelsdelivered .cont-holder span:hover {
    transform: scale(1.1) rotate(360deg);
}

.parcelsdelivered .cont-holder a {
    text-decoration: none;
}

.parcelsdelivered .cont-holder h3 {
    color: #844094;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 5px;
    text-transform: uppercase;
}

.parcelsdelivered .cont-holder p {
    font-size: 13px;
}

.parcelsdelivered .cont-holder span {
    float: left;
    margin-right: 15px;
    width: 78px;
}

.parcelsdelivered .cont-holder .cont-1 {
    float: left;
    width: 262px;
}
/* End Parcels. Delivered Yesterday. CSS */

/* home-section-2 */
.homesec2 .home-section-2 {
    padding-top: 45px;
}

.homesec2 .col-md-4 {
    padding: 0;
    position: relative;
}

.homesec2 .sec-2-img {
    max-height: 240px;
    overflow: hidden;
}

.homesec2 .sec-2-img img {
    transition: all .5s ease 0s;
    -webkit-transition: all .5s ease 0s;
    -ms-transition: all .5s ease 0s;
    -moz-transition: all .5s ease 0s;
    -o-transition: all .5s ease 0s;
}

.homesec2 .sec-2-img:hover img {
    transform: scale(1.2);
}

.homesec2 .sec-2-content {
    background: #844094;
    padding: 44px;
    text-align: center;
    color: #fff;
}

.homesec2 .sec-2-content h3 {
    border-bottom: 1px solid #fff;
    display: inline-block;
    padding-bottom: 10px;
    margin-bottom: 12px;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
}

.homesec2 .sec-2-content a {
    color: #fff;
    text-decoration: none;
    border: 1px solid #fff;
    border-radius: 4px;
    display: inline-block;
    font-size: 13px;
    line-height: 13px;
    margin-top: 15px;
    padding: 12px 35px;
    /* -webkit-transition: all .5s ease 0s;
    -ms-transition: all .5s ease 0s;
    -moz-transition: all .5s ease 0s;
    -o-transition: all .5s ease 0s; */
}

.homesec2 .sec-2-content a:hover {
    background: #fff;
    border-color: #7f3f98;
    color: #7f3f98;
}

.homesec2 .sec-2-content p {
    font-size: 14px;
}

.homesec2 .sec-2-icon {
    left: 0;
    margin-top: -32px;
    position: absolute;
    top: 50%;
    width: 100%;
    text-align: center;
}

.homesec2 .sec-2-icon img {			
    transition: all .5s ease 0s;
    -webkit-transition: all .5s ease 0s;
    -ms-transition: all .5s ease 0s;
    -moz-transition: all .5s ease 0s;
    -o-transition: all .5s ease 0s;
}

.homesec2 .sec-2-icon img:hover {
    transform: scale(1.1) rotate(360deg);
}
/* End home-section-2 */
.home-section-3 {
    background: url(images/home/bg-red.jpg) transparent;
    padding: 100px 0 85px;
    background-size: cover;
    margin-top: 55px;
    text-align: center;
    position: relative;
}

.home-section-3::before {
    background: rgba(0,0,0,.75);
    top: 0;
    height: 100%;
    position: absolute;
    width: 100%;
    background-repeat: no-repeat;
    content: "";
    left: 0;
}

.home-section-3::after {
    background: url(images/home/top-img_shedow.png) transparent;
    bottom: -47px;
    height: 50px;
    position: absolute;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    left: 0;
}

.home-section-3 .row {
    position: relative;
    z-index: 9;
}

.home-section-3 h3 {
    font-size: 80px;
    line-height: 85px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
}

.home-section-3 h3>span {
    color: red;
    margin-left: 10px;
}

section.sec-3-content {
    margin-bottom: 4.307692308em;
    font-size: .8125rem;
    line-height: 1.6153846154;
    padding-top: 1.615384615em;
}

.sec-3-content p {
    color: #fff;
    line-height: 30px;
    margin: 25px 0 45px;
    font-size: 15px;
}

p.review {
    min-height:260px;
}
.slick-slide img {
    display: initial !important;
    padding: 13%;
}
.testi_name{
    font-weight: bold;
    font-size: 17px;
}

.sec-3-content a {
    background: transparent;
    border-radius: 6px;
    color: #fff;
    display: inline-block;
    padding: 13px 40px;
    position: relative;
    text-transform: uppercase;
    z-index: 1;
    font-size: 13px;
    border: 1px solid;
    text-decoration: none;
    /* -webkit-transition: all .5s ease 0s;
    -ms-transition: all .5s ease 0s;
    -moz-transition: all .5s ease 0s;
    -o-transition: all .5s ease 0s; */
}

.sec-3-content a:hover {
    background: #844094;
    color:#fff !important;
}
/* End home-section-3 */
/* home-section-4 */
.home-section-4 {
    padding: 60px 0 0;
    position: relative;
}

.home-section-4 h3 {
    font-size: 30px;
    line-height: 35px;
    padding-top: 42px;
    border-top: 1px dashed #b2b2b2;
    text-align: center;
}

.home-section-4 p {
    text-align: center;
    font-size: 15px;
    line-height: 20px;
}

.sec-4-holder {
    margin-top: 58px !important;
    position: relative;
}

.sec-4-img-1 {
    position: relative;
    float: left;
}

.sec-4-img-1::after {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.8);
    transition: all .5s ease 0s;
    -webkit-transition: all .5s ease 0s;
    -ms-transition:all .5s ease 0s;

}

.sec-4-content {
    position: absolute;
    top: 65px;
    left: 65px;
}

.sec-4-content h2 {
    font-size: 44px;
    color: #fff;
    text-align: left;
}

.sec-4-content a {
    width: 185px;
    background: #fd4a4a;
    padding: 6px 12px;
    height: 40px;
    line-height: 30px;
    font-size: 15px;
    color: #fff;
    margin-top: 25px;
    text-transform: uppercase;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    border-radius: 4px;
    /* -webkit-transition: all .5s ease 0s;
    -ms-transition: all .5s ease 0s;
    -moz-transition: all .5s ease 0s;
    -o-transition: all .5s ease 0s; */
}

.sec-4-content a:hover {
    background: #844094;
    color:#fff !important;
}

.sec-4-img-2 {
    position: absolute;
    right: 110px;
    top: -15px;
}

.sec-4-img-2 div {
    height: 402px;
    position: relative;
    float: left;
    overflow: hidden;
}

.sec-4-img-2 div img {
    height: 100%;
    transition: all .5s ease 0s;
    -webkit-transition: all .5s ease 0s;
    -ms-transition: all .5s ease 0s;
    -moz-transition: all .5s ease 0s;
}

.sec-4-img-2 div:hover img {
    background: rgba(0,0,0,.9);
    transform: scale(1.2);
}

.sec-4-img-2 div::after {
    border: 15px solid #7f3f98;
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
    bottom: 0;
    content: "";
}
/* End home-section-4 */
/* home-section-5 */
.home-section-5 {
    padding: 40px 0 10px;
}

.sec-5-title {
    border-top: 0;
    font-size: 44px;
    padding-top: 0;
    margin-bottom: 30px;
    letter-spacing: 1px;
    text-transform: uppercase;
    line-height: 45px;
    margin-top: 54px;
    text-align: center;
}

.sec-5-content {
    padding-bottom: 41px;
    border-top: 1px dashed #b2b2ac;
    display: inline-block;
    margin: 0 auto;
    padding-top: 40px;
    width: 885px !important;
    max-width: 100% !important;
}

.grid-col:first-child {
    margin-left: 0;
}

.grid-col {
    overflow: hidden;
    position: relative;
    display: inline-block;
    margin-left: 26px;
}

.sec-5-img::after {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.8);
    transition: all .5s ease 0s;
    -webkit-transition: all .5s ease 0s ;
    -ms-transition:all .5s ease 0s;
}

.sec-5-img img, .sec-5-img-2 img {
    overflow: hidden;
    transition: all .5s ease 0s;
    -webkit-transition: all .5s ease 0s;
     -ms-transition:all .5s ease 0s;
}

.grid-col:hover .sec-5-img img, .grid-col:hover .sec-5-img-2 img {
    transform: scale(1.1);
}

.grid-col:hover .sec-5-img::after {
    background: rgba(127,63,152,.9);
}

.sec-5-img-2 {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    margin-top: 45px;
    text-align: center;
}

.sec-5-img-2 h3 {
    color: #fff;
    font-size: 33px;
    margin-top: 20px;
}
/* End home-section-5 */
.testimonail {
    width:98% !important;
    border:1px solid rgb(92, 84, 84);
    margin-right: 3px;
    background-color: #fff;
}
section.testmon {
    float: left;
    position: relative;
    width: 100%;
    text-align: center;
 
}

.weight-section select.form-select{
    background-color: #f7f7f7;
    /* background-image: -webkit-linear-gradient(rgba(255,255,255,0),rgba(255,255,255,0)); */
    border: 1px solid #d1d1d1;
    border-radius: 2px;
    color: #686868;
    padding: 0.625em;
    width: 100%;
}
.headerlogo{
    width:11%;
}

.copybtn{
    float: right;
    background: #f3c41a;
     color: purple;
    padding: 2px;
    border-radius: 19px;
    font-size: 11px;
    margin-top: 1%;
    cursor: pointer;
    text-decoration: none;
}

@media (max-width: 767px){
.headerlogo{
    width: 35%;
    padding: 0 !important;
}

    .quick-holder {
       display:block;
    }

    .sec-4-img-1 {
        float: none;
        overflow: hidden;
    }

    .sec-4-img-2 {
        position: relative;
        margin-top: 30px;
        overflow: hidden;
        top: 0;
        right: 0;
    }

    .sec-4-img-2 div {
        float: none;
    }

    .tab-tittle-page {
        background-color:#7f3f98;
    }

    .quick-quote-home-bg {
        display: none;
    }

    .quick-quote-home {
        margin-top: 0;
    }

    .tab-tittle-page div {
        display: none;
    }

    .tab-quick-quote .container {
        max-width: 100%;
    }

    .grid-col {
        margin-left: auto;
        width: 100%;
    }

    .tab-tittle-page h1 {
        font-size: 150%;
    line-height: 31px;
    padding: 6%;
    }

    .quick-holder .col-md-5.col-sm-5, .quick-holder .col-md-7.col-sm-7 {
        width: 100%;
    }

    .tab-package-document, .tab-ltl-ftl {
        /* padding-left: 22rem; */
        float:right
    }

    .container.date_ship .col-md-4.col-sm-4, .container.date_ship .col-md-6.col-sm-6 {
        width: 100%;
    }
    
    .container.date_ship .wrape_promo{
        margin: 20px;
    }

    .homesec2 .sec-2-img, .homesec2 .sec-2-icon {
        display: none;
    }

    .pdy-img-holder {
        display: none;
    }

    section.home-section-2.homesec2 .col-md-4 {
        margin-bottom: 3px;
    }

    .home-section-3 h3 {
        font-size: 56px;
    }

    .sec-4-content h2 {
        font-size: revert;
    }

    .parcelsdelivered .cont-holder .cont-1{
        width: 100%;
    }
    .parcelsdelivered .cont-holder {
        text-align: center;
    }
    .parcelsdelivered .cont-holder span {
        width:100%;
        float: initial;
    }
    .parcelsdelivered .cont-holder {
        margin-top:15px;
    }
    button#get_quote {
        padding: 7px 9px;
    }
    .cont-holder {
        background: #f5f5f5;
        width: 100%;
    padding: 2%;

    }

    li.nav-item {
        font-size: 13px;
    }

    section.your_business_page.fw {
        padding: 0;
    background: #7f3f98;
    background-image: none !important;
    }

    .quotetable thead
    {
        float:left
    }
   /* .quotetable tbody
    {
        float:right
    } */
    
    .quotetable thead th {
        display: block;   
    }
    .quotetable tbody td
    {
        display: block;
        width: 150px !important;
    } 
    
    .tab-package-document{
        padding-left: 0!important;
        }
        .tab-package-document a{
        margin-right:0px !important;
        font-size: 13px;
        }
        .tab-in-export-import a{
            font-size: 13px;
        }
        table.table.table-bordered.table-striped.quotetable{
            padding-left: 10%;
            }

    /* .tab-package-document{
        padding-left: unset !important;
    }
    .in-send-package{
        font-size:11px !important;
    }
    .package-document{
        font-size:11px !important;
    } */

    .tab-tittle-page h1 {
        margin-top: 0px !important;
    }
   
}

@media (max-width: 425px){
    .tab-tittle-page h1 {
        font-size: 150%;
        line-height: 31px;
    padding: 6%;
    }
    button#get_quote {
        padding: 7px 9px;
    }
    .tab-package-document, .tab-ltl-ftl {
        padding-left: 5rem;
    }
    ul.from-to>li{
        width:100%
    }
    .weight-section li {
        width: 46.6%!important;
    }

    .home-section-3 h3 {
        font-size: 56px;
    }

    .sec-4-content h2 {
        font-size: revert;
    }
    .parcelsdelivered .cont-holder .cont-1{
        width: 100%;
    }
    .parcelsdelivered .cont-holder {
        text-align: center;
    }
    .parcelsdelivered .cont-holder span {
        width:100%;
        float: initial;
    }

    .parcelsdelivered .cont-holder {
        margin-top:15px;
    }
    .cont-holder {
        background: #f5f5f5;
        width: 100%;
    padding: 2%;

    }
    section.your_business_page.fw {
        padding: 0;
    background: #7f3f98;
    background-image: none !important;
    }

    .quotetable tbody td
    {
        display: block;
        width: 100% !important;
    } 
    table.table.table-bordered.table-striped.quotetable{
        padding-left: 5%;
        }
    /* .tab-package-document{
        padding-left: unset !important;
    }
    .in-send-package{
        font-size:11px !important;
    }
    .package-document{
        font-size:11px !important;
    } */
  
}

.quote p{
    font-size:14px !important;
    
    font-family: 'Lato';
}
.quote h3{
    margin-top: 9px;
    font-size: 15px;
    letter-spacing: .5px;
    font-family: 'Lato';
    color: #000;
    font-weight: bold;
}
.quote th,.quote td{
    text-align:center
}

p,h1,h2,h3,h4,select,input,label{
    font-family: 'Lato'!important;
}
p{
    margin-bottom: 6px !important;
}
body{
    font-family: 'Lato'!important;
}

.quotefirst{
    margin-left:21%
}
.quotebox{
    text-align:center
}

.fordesk{
    display:inline-table;
}
.formobile{
    display:none;
}
@media (max-width: 767px){
    .quotefirst{
    margin-left:0
    }

    .quotebox{
        max-width: 30%;
        display: table-cell;
    }
    .mainbox{
        display:block
    }

    .fordesk{
        display:none;
    }
    .formobile{
        display:block;
    }
}

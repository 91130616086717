.context-dark, .bg-gray-dark, .bg-primary {
    color: rgba(255, 255, 255, 0.8);
}

.footer-classic a, .footer-classic a:focus, .footer-classic a:active {
    color: #ffffff;
}
.nav-list li {
    padding-top: 5px;
    padding-bottom: 5px;
}

.nav-list li a:hover:before {
    margin-left: 0;
    opacity: 1;
    visibility: visible;
}

ul, ol {
    list-style: none;
    padding: 0;
    margin: 0;
}

.social-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 23px;
    font: 900 13px/1 "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.5);
}
.social-container .col {
    border: 1px solid rgba(255, 255, 255, 0.1);
}
.nav-list li a:before {
    content: "\f14f";
    font: 400 21px/1 "Material Design Icons";
  
    display: inline-block;
    vertical-align: baseline;
    margin-left: -28px;
    margin-right: 7px;
    opacity: 0;
    visibility: hidden;
    transition: .22s ease;
}
.office1 a{
    color: #fff !important;
    text-decoration: none;
    font-size: 14px;
    font-family: lato;
    line-height: 26px;
    letter-spacing: 0px;
}

.footer-links>li a {
    font-size: 17px;
    font-family: latoblack;
    margin-bottom: 17px;
}
.copyright{
    background: #000;
    text-align: center;
    font-size: 10px;
    font-family: 'Montserrat';
}
.foottext{
    letter-spacing: 1px;
    padding:3%
}

.social_icon li a {
    color: #fff;
    font-size: 25px;
    line-height: 25px;
    margin-left: 0;
    margin-right: 0;
}
 /* subscription popup */

.shipment_cont {
    background: rgba(0,0,0,.1);
    position: relative;
    float: left;
    width: 100%;
    padding: 20px 0!important;
    margin-bottom: 20px!important;
}

.shipment_cont h4{
    font-size:15px;
    text-transform:uppercase;
    text-align:center
}

.get_itms span {
    background: #e9e9e9;
    padding: 0 12px!important;
    position: relative;
    z-index: 9;
    font-size: 15px;
    font-style: italic;
    color: #864a9d;
    font-weight: 400;
}


  .hr-lines{
    position: relative;
    /*  new lines  */
    max-width: 500px;

    text-align: center;
  }


  ul.get_itms {
    padding-left: 0px;
}

.footer p {
    margin-bottom: 0px;
}

ul.get_itms li {
    float: left;
    width: 33.33%;
}

ul.get_itms p {
    display: inline-block;
    padding-left: 10px!important;
    text-align: left;
    vertical-align: middle;
}

ul.get_itms li i {
    color: #cacaca;
    display: inline-block;
    font-size: 25px;
    vertical-align: middle;
}

.modal-body {
    padding: 0 !important;
}

.hr-lines {
    position: relative;
    max-width: 100%;
    text-align: center;
}

.hr-lines:after {
    content: " ";
    height: 2px;
    width: 186px;
    background: #864a9d;
    display: block;
    position: absolute;
    top: 62%;
    right: 0;
}

.hr-lines:before {
    content: " ";
    display: block;
    height: 2px;
    width: 186px;
    position: absolute;
    top: 62%;
    left: 0;
    background: #864a9d;
}

.get_itms span {
    background: #e9e9e9;
    padding: 0 12px!important;
    position: relative;
    z-index: 9;
    font-size: 18px;
    font-style: italic;
    color: #864a9d;
    font-weight: 400;
}

.subscribeform{
	width: 62%;
    margin: 0 auto;
}

@media (min-width: 576px){
    .modal-dialog {
        max-width: 567px;
    }
}

svg.svg-inline--fa.fa-bell, svg.svg-inline--fa.fa-circle-check,svg.svg-inline--fa.fa-clock {
    color: #a19898;
}

.btn-close{
    border:1px solid;
    border-radius:16px;
    color:#9d9292
}

ul.social_icon li {
    width: 23%;
}
.footer svg:not(:root).svg-inline--fa,.footer svg:not(:host).svg-inline--fa{
font-size: 23px;
}

.social_icon li a:hover:not(.active) {
    background: none;
}

.fade.modal.show {
    padding-left: 0px !important;
}


/* Promoti */
.promocountry .modal-header {
	position: absolute;
    right: 0px;
    border: 0px;
    z-index: 9999;
}

.promocountry .btn-close {
    border: 0px;
}

.promocountry .first-t {
    font-size: 22px;
    font-weight: 600;
}

.promocountry .promohol {
    padding-left: 40px !important;
    padding-right: 40px !important;
    padding-top: 30px !important;
    padding-bottom: 30px !important;
}

.first-t1, .first-t2, .first-t3, .first-t4 {
    text-align: center;
}

.promocountry .first-t1 {
    font-style: italic;
    font-weight: 600;
    margin-bottom: 15px;
    font-size: 18px;
}

.promocountry .first-t2 {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 40px;
}

.promocountry .first-t2 span{
    font-size: 26px;
    font-weight: 600;
    color: rgb(184, 15, 170);
}

.promocountry .first-t3 {
    font-size: 26px;
    font-weight: 600;
    text-decoration: underline;
    margin-bottom: 10px;
    font-style: italic;
    color: rgb(184, 15, 170);
}

.promocountry .first-t4 {
    font-size: 15px;
}

.promocountry .promo-submit {
    text-align: center;
}

.promocountry .promobgimg {
	background-image: url(images/promocountry/3.jpg);
    border: initial;
    border-style: none;
    border-width: 0px;
    border-left-width: 1px;
    border-right-width: 1px;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-color: #000;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 25px;
    padding-bottom: 25px;
	background-size: cover;
	background-position: center center;
}

@media (min-width: 576px){
    .promocountry .modal-dialog {
        max-width: 830px;
    }
}

.rloader {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: relative;
    display: inline-block;
    animation: rotate 1s linear infinite
  }
  .rloader::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: 5px solid #7b3995;
    animation: prixClipFix 2s linear infinite ;
  }

  .pro-submit {
    background-color: #7b3995;
  }
  
  @keyframes rotate {
    100%   {transform: rotate(360deg)}
  }
  
  @keyframes prixClipFix {
      0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
      25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
      50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
      75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
      100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
  }

.top_header ul li a i.fa-phone, .top_header ul li a i.fa-whatsapp {
    border: 1px solid orange;
    padding: 0;
    width: 18px;
    height: 18px;
    border-radius: 20px;
    color: orange;
    text-align: center;
    line-height: 18px;
    font-size: 11px;
}

svg.svg-inline--fa.fa-phone {
    padding: 3px;
    border-radius: 50px;
    font-size: 10px;
    height: 9px;
    border-radius: 20px;
    color: #ffa500;
    text-align: center;
    border:1px solid
}

.navbar a {
    color: #000;
    /* font-family: ms pgothic; */
    font-size: 14px;
    line-height: 22px;
  
    border-bottom: 4px solid transparent;
    font-weight: 700;
}


li a:hover:not(.active) {
     background-color:#fff;
    color: rgb(184, 15, 170) !important;
    border-bottom: 2px solid  !important;
}
.navbar a.active{
    border-bottom: 2px solid #7f3f98;
    color: #7f3f98;
}

.bg-light {
    background:#fff !important;
    background-color:#ffffff !important;
}
nav.navbar.navbar-expand-lg.navbar-light.bg-light{
    background:#fff !important;
    background-color:#ffffff !important;
}

.your_business_page {
    background-size: 100%;
    position: relative;
    padding: 150px 0 80px;
}
.your_business_page:after {
    background: rgba(0,0,0,.3);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}


.business_content>p {
    color: #fff;
    font-family: 'Raleway';
    font-size: 22px;
    line-height: 38px;
    margin-bottom: 5px;
    margin-top: 25px;
    font-weight:600;
    letter-spacing: 1px;
}

.says, .screen-reader-text {
    clip: rect(1px,1px,1px,1px);
    height: 1px;
    overflow: hidden;
    position: absolute!important;
    width: 1px;
    word-wrap: normal!important;
}
label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
}

.form-help input#searchsubmit {
    color: #fff;
    font-family:'Railway';
    letter-spacing: 1px;
    font-size: 17px;
    font-weight: 400;
    padding: 5px 64px;
    transition: all .5s ease 0s;
    -webkit-transition: all .5s ease 0s;
    -ms-transition: all .5s ease 0s;
    -o-transition: all .5s ease 0s;
    -moz-transition: all .5s ease 0s;
    background-color: #000;
}
form#searchform input {
    width: auto;
    font-size: 13px;
}
.form-help input {
    border-radius: 0;
    height: 50px;
    padding: 5px 15px;
    vertical-align: top;
}

form#searchform input[type=text] {
    min-width: 300px;
}
.form-help #s {
    display: inline-block;
    width: 65%;
}

.form-help, .your_business {
    position: relative;
    z-index: 9;
}

.form-help input#searchsubmit:hover {
    background: #7f3f96;
}

.become_driver >h5{
   
        color: #7f4098;
        font-family: 'Lato';
        font-weight: 600;
    
}

.titele-text {
    font-family: raleway;
    font-weight: 700;
    font-size: 35px;
    color: #feb300;
    margin-bottom: 3%;
}
.purpel-text {
    color: #7f3f98;
    font-family: 'Lato';
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 5px;
    text-transform: uppercase;
    font-weight: 500;
  
}
.helping-cont {
    float: left;
    width: 281px;
}
.helping-cont .paragrph {
    text-align: left;
    margin: 0;
    font-size: 13px;
    line-height: 20px;
    font-family: lato;
    font-weight: 400;
}
.home_ser{
    margin-bottom:27%
}
.helping-cont >a{
    text-decoration: none;
}
.zone-content {
    background: #844094;
    padding: 44px;
}
.zone-content .purpel-text {
    border-bottom: 1px solid #fff;
    color: #fff !important;
    display: inline-block;
    padding-bottom: 10px;
    margin-bottom: 12px;
}
.zone-content .paragrph {
    color: #fff;
    font-family: 'Lato';
    font-weight: 200;
    font-size: 14px;
}
/* .row>*{
    padding-left:0px !important;
}
.row {
    --bs-gutter-x: 0;
} */

.zone-content a.more:hover {
    background: #fff;
    border-color: #7f3f98;
    color: #7f3f98;
}
.more {
    border: 1px solid #fff;
    border-radius: 4px;
    display: inline-block;
    font-size: 13px;
    line-height: 13px;
    color:#fff;
    margin-top: 15px;
    padding: 12px 35px;
    font-family: open_sansregular;
    text-decoration: none;
}
.client-zone-icon {
    left: 20%;
    margin-top: 15%;
    position: absolute;
    width: 5%;
    transition: all .5s ease 0s;
}
.client-zone-icon2 {
    left: 47%;
    margin-top:15%;
    position: absolute;
    width: 5%;
    transition: all .5s ease 0s;
}
.client-zone-icon3 {
    left: 75%;
    margin-top: 15%;
    position: absolute;
    width: 5%;
    transition: all .5s ease 0s;
}
.zone_icon:hover {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
   
  }
  .zone_icon{
    z-index: 99999999999;
  }
  .zone_img:hover{
    transform: scale(1.03);
  }
  .have_your_sec {
    background: url(images/home/bg-red.jpg) transparent;
    padding: 130px 0 100px;
    background-size: cover;
    margin-top: 55px;
}
.darkoverlay {
    background: rgba(43, 43, 44, 0.35);
 text-align: center;
  width: 100%;
  height: 100%;
  background-size: cover;
}
.have_your_sec .row {
    position: relative;
    z-index: 9;
}
.have_your_sec .title_text_red {
    font-size: 80px;
    line-height: 85px;
}
.title_text_red {
    color: #fff;
    text-transform: uppercase;
}
.title_text_red>span {
    color: red;
    /* font-family: nirmala ui!important; */
    font-weight: 400!important;
    margin-left: 10px;
}
.have_your_sec .title_text_red {
    font-size: 80px;
    line-height: 85px;
}
.view-more {
    background: transparent;
    border-radius: 6px;
    color: #fff;
    display: inline-block;
    padding: 13px 40px;
    position: relative;
    text-transform: uppercase;
    z-index: 1;
    font-family: open_sansregular;
    font-size: 13px;
    border: 1px solid;
}
section.help-let1 {
    float: left;
    padding: 60px 0 0;
    position: relative;
    width: 100%;
    text-align: center;
 
}
.help-let_h3 {
    font-size: 30px;
    line-height: 35px;
    font-family:'Railway';
    padding-top: 42px;
    border-top: 1px dashed #b2b2b2;
}
.let_h3 {
    color: #000;
    text-align: center;
}
.help-let1 p {
    font-size: 15px;
    line-height: 20px;
    font-family: 'Railway';
}

.expert-cont {
    position: absolute;
    top: 45%;
    left: 18%;
   color: #fff;
}
.expert-cont a {
    width: 185px;
    background: #fd4a4a;
    padding: 6px 12px;
    height: 40px;
    line-height: 30px;
    font-size: 15px;
    color: #fff;
    margin-top: 25px;
    font-family: open_sansregular;
    text-transform: uppercase;
    display: inline-block;
    text-align: center;
    border-radius: 3px;
}
.expert-cont>a:hover {
    background: #7f3f98;
}
.expert_img{
    border:12px solid #844094;
    margin-top:-4%
}

section.community {
    float: left;
    position: relative;
    width: 100%;
    text-align: center;
 
}
@media (max-width: 767px){
   .home_ser{
       text-align:center;
       background: #d5c8c836;
   }
    .img_mob{
        display:none;
    }
    .purpel-text {
        font-size:10px;
    }
    .ser_icon{
        width:20%
    }

    .navbar a {
        padding: 0 15px 7px;
    }
}

.expert-cont1{
    padding: 12%;
    color: #fff;
    font-weight: 700;
}
.expert-cont1>h3{
    font-weight: 700;
}
.comm:hover{
    background: #7f3f96 !important;
}
.community_sec {
    padding-bottom: 41px;
    border-top: 1px dashed #b2b2ac;
   
    padding-top: 40px;
   
    max-width: 100%;
}
.help-let_h4 {
    font-size: 30px;
    line-height: 35px;
    font-family:'Railway';
    padding-top: 42px;
   
}
.slick-slider.slick-initialized {
    margin-left: 10%;
    margin-right: 10%;
}
.slick-slide img {
    display: initial;
}
.testimonail {
    width:98% !important;
    border:1px solid rgb(92, 84, 84);
    margin-right: 3px;
    background-color: #fff;
}
section.testmon {
    float: left;
    position: relative;
    width: 100%;
    text-align: center;
 
}
svg.svg-inline--fa.fa-star{
    color:#ffb900
}
.helpingrow{
    max-height: 232px;
}
.r_more_details {
    cursor: pointer;
    display: block;
    margin-top: 10px;
}
.r_details ul li {
    display: inline-block;
    font-size: 13px;
}
.r_details ul {
    position: relative;
    margin: 0 10px;
    border-bottom: 1px solid;
}
.r_details ul li.r-right {
    float: right;
    margin-right: 40px;
}

.removebtn
    {
        float: right;
        background: #f14f11;
        color: #fff;
        border: 1px solid #201e1c73;
        border-radius: 50%;
        font-size: 12px;
    font-weight: 600;
    }

/* Parcels. Delivered Yesterday. CSS */
.parcelsdelivered.sec-1-title {
    text-align: center;
    color: #feb300;
    font-size: 35px;
}

.parcelsdelivered.pdy-img-holder {
    margin-top: 43px;
    overflow: hidden;
}

.parcelsdelivered.cont-holder {
    margin-top: 75px;
    float: left;
}

.parcelsdelivered.cont-holder span {
    transition: all .5s ease 0s;
    -webkit-transition: all .5s ease 0s;
    -ms-transition: all .5s ease 0s;
    -moz-transition: all .5s ease 0s;
    -o-transition: all .5s ease 0s;
}

.parcelsdelivered.cont-holder span:hover {
    transform: scale(1.1) rotate(360deg);
}

.parcelsdelivered.cont-holder a {
    text-decoration: none;
}

.parcelsdelivered.cont-holder h3 {
    color: #844094;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 5px;
    text-transform: uppercase;
}

.parcelsdelivered.cont-holder p {
    font-size: 13px;
}

.parcelsdelivered.cont-holder span {
    float: left;
    margin-right: 15px;
    width: 78px;
}

.parcelsdelivered.cont-holder .cont-1 {
    float: left;
    width: 262px;
}
/* End Parcels. Delivered Yesterday. CSS */

.sonoco_main-cont {
    display: inline-block;
    margin: 0 -13px 0 -7px;
  }
  
  .sonoco_main-cont h2 {
    font-size: 19px;
    font-weight: 600;
  }
  
  .sonoco_name {
    border: 1px solid #ddd;
    align-content: center;
    align-self: center;
    background: #fff;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    height: 210px;
    margin: 0 8px 20px;
    padding: 0;
    text-align: center;
    width: calc(33% - 20px);
    position:relative;
  }
  
  .sonoco-content {
    background: #fefefe;
    border: 1px solid #eaeae7;
    box-shadow: 0 0 2px rgb(204 204 204 / 80%);
    float: left;
    margin: 0 10px 20px;
    padding: 20px;
    width: calc(100% - 22px);
    position: relative;
  }
  
  .services {
    text-decoration: none;
    color: #000;
    display: block;
    vertical-align: middle;
    position: relative;
    padding: 22px 24px;
  }
  
  .sonoco_name.active:after {
    border-top: 15px solid #0a083e;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    content: '';
    position: absolute;
    bottom: -15px;
    left: 50%;
    margin-left: -12px;
  }
  
  .sonoco-content table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid #ddd;
  }
  
  .sonoco-content table tr th, .sonoco-content table tr td {
    text-align: center;
    border: 1px solid #ddd;
  }
  
  .service-1, .service-2, .service-3, .service-4, .service-5, .service-6, .service-7, .service-8, .service-9{
    display: block !important;
  }

  .table-holder{
    overflow-x:auto;
  }

  .ul-holder {
    list-style: none;
    padding: 0;
    margin: 0;
    display: initial;
}

.ul-holder li {
    display: table-cell;
    border: 1px solid #ddd;
    padding: 8px 10px;
    float: none;
    text-align: center;
}

.ul-holder li h3 {
    font-size: 16px;
    font-weight: 600;
}

.sonoco_form{
    background-color: #fff;
    padding: 18px 50px;
}

  
@media (max-width: 767px) {
    .sonoco_name {
      width: 100%!important;
      margin: 0 0 20px;
    }

    .ul-holder li {
        display: block;
    }

    .sonoco_main-cont {
        display: inline-block;
        margin: 0;
    }
  }
.order .stepwise-row {
    font-family: 'Lato';
    clear: both;
    font-size: 16px;
    line-height: 20px;
    background: #7f3f98;
}
.order .stepwise-row a {
    display: block;
    border-right: 1px solid #fff;
    font-size: 15px;
    text-transform: uppercase;
    color: #fff;
    padding: 10px;
    line-height: 25px;
    transition: .5s;
    text-decoration: none;
}
a{
    text-decoration: none;
   
}
button,.btn{
    border-radius:30px !important;
    min-width: 200px;
    max-width: 200px;

}
.submit a {
    border-radius: 30px !important;
    min-width: 200px;
    max-width: 200px;
}

.tab-quick-quote .nav-tabs .nav-link{
    border-radius: 6px !important;
    min-width:  max-content;
    max-width:  max-content;
}
.noradius{
    border-radius: 6px !important;
    min-width:  max-content;
    max-width:  max-content;
}
button.btn-close {
    border-radius: 6px !important;
    max-width: max-content;
    min-width: max-content;
}
#get_quote{
   
    max-width:  max-content;
}
.removebtn{
    min-width:  max-content;
}

.navbar-toggler{
    border-radius: 6px !important;
    min-width:  max-content;
    max-width:  max-content;
}

.order .active {
    background: #000;
}
.order .r_sub {
    font-family: 'Lato';
    width: 172px!important;
    background: #7f3f98;
    color: #fff;
    border-radius: 6px;
    padding: 11px;
    border: none;
}
.order .parsel_container .gray_box {
    background: #f0f0f0;
    border: 1px solid #f0f0f0;
    display: inline-block;
    margin: 0 0 10px;
    padding: 20px 15px 10px;
    width: 100%;
}

.order .order-cont .input-group-addon {
    color: #000;
    font-family: latobold;
}
.order .input-group-addon:first-child {
    border-right: 0;
}
.order .input-group-addon {
    padding: 12px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.order ul.customradio {
    background-color: #f1f1f1;
    list-style: outside none none;
    margin: 15px auto;
    overflow: auto;
    padding: 10px;
    text-align: center;
}
.order ul.customradio li {
    color: #aaa;
    display: inline-block;
    position: relative;
    float: none;
    width: auto;
}
.order ul.customradio li:first-child {
    width: 100%;
}
.order ul.customradio li h2 {
    font-size: 22px;
    margin: 15px 0;
    color: #000;
    font-weight: 700;
    text-transform: uppercase;
}

.order ul.customradio li label {
   
    position: relative;
    font-weight: 300;
    font-size: 1.35em;
    padding: 0 25px 25px 35px;
    margin: 10px auto;
    height: 20px;
    z-index: 9;
    cursor: unset;
   
    pointer-events: none;
}

input#s-option {
    cursor: pointer;
}
.order input[type=radio]:checked~label {
    color: #7f3f98;
}
.order input[type=checkbox], input[type=radio] {
    margin: 4px 0 0;
    margin-top: 1px\9;
    line-height: normal;
}
.order tr td {
    text-align: center;
}

.order .servies-content .btn-success {
    border-color: #7f3f98;
}
.order .servies-content .btn {
    background: #7f3f98;
    text-transform: uppercase;
    float:right;
    margin-bottom: 4%;
}

.awblabel .table-bordered {
    border: 1px solid #ddd;
}
.awblabel table {
    background-color: transparent;
    display:block;
page-break-inside:avoid !important;
}
.awblabel table tr{
page-break-inside: avoid;
}


.awblabel .thank-you-page {
    width: 65%;
    margin: 0 auto 15px auto;
}

.awblabel .become_driver.thank-you-page {
    float: none;
    padding: 0;
    background: 0 0;
}
.awblabel p.received_text {
    
    display: block;
    text-align: center;
    width: 67%;
    margin: 10px auto 15px;
    padding: 0 15px;
    font-size: 15px;
}



.required{
    color:red
}
.order h3.sectiontitle{
    font-size: 24px!important;
    color: #4a4a4a;
}


a.orderBackButton{
    background: purple;
    color: #fff;
    padding: 5px;
    border-radius: 30px;
}

.suggest{
    border-bottom: 1px solid #746e6e0a;
    padding: 3%;
    cursor: pointer;
    margin-bottom: 0px !important;
    background: rgb(179 179 183 / 17%);
}


.error
{
    border: 1px solid red !important;
}
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Montserrat:wght@400;500&family=Raleway:wght@500;600;700&family=Roboto:wght@300;400&display=swap');


.business_content h2 {
    color: #fff;
    font-size: 50px;
    font-weight: 500;
    line-height: 60px;
    text-transform: uppercase;
}
.business_content span {
    display: block;
    font-size: 2.3rem;
  
}
.business_region {
    float: left;
    width: 100%;
    margin-bottom: 20px;
}
.business_region select.form-control, .form_enquiry .enquiry select {
    font-size: 15px;
    height: 60px;
}
.business_region select.form-control, .form_enquiry .enquiry select {
    -moz-appearance: none;
    background-image: url(images/caret-down.png);
    background-position: 98% 13px;
    background-repeat: no-repeat;
    background-size: 38px auto;
    text-indent: 0.01px;
    width: 95%;
    border: 1px solid #e3e1e1;
    background-color: #f7f7f7;
}
.mybusiness .business_region>li {
    display: inline-block;
    vertical-align: middle;
    max-width: 33%;
    width: 100%;
    position: relative;
    z-index: 9;
}
.mybusiness.business_region>li:nth-child(2) {
    margin: 0 5px;
}
.buss button.form-control {
    margin-top: 54px;
}
.mybusiness .business_region button.form-control {
    background: #7f3f98;
    color: #fff;
    text-transform: uppercase;
    
}
.mybusiness .business_region>li label {
    color: #fff;
    font-family: 'raleway';
    font-weight: 700;
    font-size: 28px;
    padding-bottom: 8px;
    text-align: left;
    width: 100%;
}
.your_business_page.fw:after {
    background: rgba(0,0,0,.3)!important;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}
.cta-header {
    display: inline-flex;
    font-family: lato;
    font-size: 30px;
    font-weight: 700;
    line-height: 50px;
    margin-bottom: 0;
    padding-bottom: 32px;
    padding-right: 0;
    padding-top: 48px;
    text-transform: none;
}
.btn--black {
    background-color: transparent;
    border: 2px solid #000;
    border-radius: 0;
    font-family: 'raleway';
    font-weight: 700;
    font-size: 21px;
    line-height: 21px;
    margin: 0 0 0 90px;
    max-width: 240px;
    padding: 10px 52px;
    text-transform: uppercase;
    
}
.btn--black, .cta-header {
    color: #000;
    transition: all .5s ease 0s;
    text-align: center;
}
.business_needs_sec h2 {
    color: #000;
    font-family: 'raleway';
    font-size: 30px;
    margin-bottom: 50px;
    font-weight: 800;
}
.business_needs_sec {
    padding: 56px 0;
}
.business_img{
    background-image: url(images/banners/bus.jpg); 
    
    background-size: cover;
}
.business_img2{
    background-image: url(images/banners/bus2.jpg); 
   
    background-size: cover;
}
.business_img3{
    background-image: url(images/banners/bus3.jpg); 
   
    background-size: cover;
}


